import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Heading from "../components/Heading";
import Layout from "../components/Layout";
import RecentCaseStudy from "../components/sections/sidebar-content/RecentCaseStudy";
import CaseStudyList from "../components/content/CaseStudyList";
import Tag from "../components/sections/sidebar-content/Tag";
import Seo from "../components/SEO";

const CasosTemplate = ({ pageContext, data }) => {
  const {
    data: { node },
  } = pageContext;

  const posts = data.allContentfulCasoDeEstudio.nodes;
  const recents = data.recentPostStudyCases.nodes;
  const tags = data.allTags.nodes;
  const heading = data.markdownRemark.frontmatter;

  const casoList = () => {
    let flag;

    posts.forEach((post) => {
      if (post.id === node.id) {
        flag = (
          <CaseStudyList
            key={post.id}
            title={post.title}
            excerpt={post.excerpt.excerpt}
            image={post.featuredImage.gatsbyImageData}
            alt={post.featuredImage.title}
            date={post.publishDate}
            slug={post.slug}
            tags={post.tags}
          />
        );
      }
    });

    return flag;
  };

  return (
    <Layout>
      <Helmet>
        <title>Casos de Estudio | RöDigital</title>
      </Helmet>
      <Heading
        title="Nuestros Casos de Estudio"
        subtitle="Todos los casos de estudio que hemos publicado."
        image={heading.imagecontact.childImageSharp.gatsbyImageData}
      />

      <section className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-8">{casoList()}</div>
            <div className="col-md-4">
              <div className="widget bg-light">
                <h5 className="mb-4">Lo más reciente</h5>
                {recents.map((recent) => (
                  <RecentCaseStudy
                    key={recent.id}
                    title={recent.title}
                    date={recent.publishDate}
                    image={recent.featuredImage.gatsbyImageData}
                    slug={recent.slug}
                    alt={recent.featuredImage.title}
                  />
                ))}
              </div>
              <div className="widget bg-light">
                <h5 className="mb-4">Etiquetas</h5>
                <ul className="list-unstyled blog-tags m-0">
                  {tags.map((tag) => (
                    <Tag key={tag.id} tags={tag.tags} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default CasosTemplate;

export const data = graphql`
  {
    allContentfulCasoDeEstudio {
      nodes {
        slug
        id
        title
        tags
        excerpt {
          excerpt
        }
        featuredImage {
          gatsbyImageData(formats: WEBP, width: 775, height: 515)
          title
        }
        publishDate(formatString: "MMMM D, YYYY", locale: "es")
      }
    }
    recentPostStudyCases: allContentfulCasoDeEstudio(
      sort: { fields: publishDate, order: DESC }
      limit: 3
    ) {
      nodes {
        id
        slug
        title
        featuredImage {
          gatsbyImageData(formats: WEBP, width: 775, height: 515)
          title
        }
        publishDate(formatString: "MMMM D, YYYY", locale: "es")
      }
    }
    allTags: allContentfulCasoDeEstudio {
      nodes {
        id
        tags
      }
    }
    markdownRemark {
      frontmatter {
        imagecontact {
          childImageSharp {
            gatsbyImageData(formats: WEBP)
          }
        }
        altimage
      }
    }
  }
`;

export const Head = ({ pageContext }) => {
  const {
    data: { node },
  } = pageContext;

  return (
    <Seo
      title={node.title}
      description={node.excerpt.excerpt}
      metaImage={node.featuredImage.gatsbyImageData}
      metaSiteUrl={`https://rodigital.com.ar/${node.slug}`}
    />
  );
};